import "./ToptraderList_styles.scss";
import { useState, useRef } from "react";
import './search.css'; //button styles

import { Text,Box,HStack,VStack,Spacer,Flex } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { Button } from "@chakra-ui/button";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";

import i18n from "../../i18n"
import glovar from './glovar';
import eventBus from "../eventBus";
import ShowSearchBar from "./ShowSearchBar"
import emailjs from "@emailjs/browser"
import { FiBell, FiSettings, FiSearch, FiLogOut, FiLogIn, FiMessageSquare, FiHeart } from "react-icons/fi";

import { removeUserSession } from '../../utils/Common';


export default ({ showSearch,showWebApp}) => {
  
  const [showPopPup,setshowPopPup] = useState(false);
  const [email,setEmail] = useState("");
  const [keyform,setKeyForm] = useState(Date.now());

  let SHOW_WEBAPP=showWebApp;

  let SHOW_SEARCHBAR=false;
  if(showSearch) {
    SHOW_SEARCHBAR=showSearch;
  }
  var templateParams = {
    email_subscriber: email,
};

  
function logout()  {
  console.log("logout!!!!!!!!!!!")
  glovar.USER_LOADED=false;
  glovar.MODE_USER=0;
  glovar.current_porfolio_ED=null;
  removeUserSession();

  //setEmail(" ")
  window.location.replace('/home')
          // setshowPopPup(true);
  
 /*
 this.setState({ email: "",
 password: ""});*/
}


  const sendEmail = (e) => {
    e.preventDefault();

    try {
    if(email.length>10 && email.includes("@") && email.includes(".")) {

     

     templateParams.email_subscriber=email;
     emailjs.send(glovar.REF_EMAIL_SERVICE, glovar.REF_EMAIL_TEMPLATE, templateParams, glovar.REF_EMAIL_KEY)
     .then(function(response) {
       //console.log('SUCCESS!', response.status, response.text);
     }, function(error) {
       //console.log('FAILED...', error);
     });
    }
    else {
      
    }

    }
    catch(Exxx) {}
  };



  function sendEventGA (category, action, label) {
    try {
      eventBus.dispatch("EventGA", { category, action, label });
    }
    catch(Exx) {}
  }

  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return(

      <Box>
<Box minHeight="100px">
      {showPopPup&&
        <Box zIndex={3} background="red"position="absolute"
  >
          <div className='popup'>
          <div className='popup_inner_mobile'>
          <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={2} pb={20} alignContent="center">
          <Text textAlign="center" fontSize="24" textColor="black" fontFamily="Tahoma" fontWeight="semibold" marginBottom={2}> 
          {i18n.t("op_no_dispo")}
            </Text>
            <Text textAlign="center" mt={10} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("quieres_activa")}  <strong>{i18n.t("subs_news")}</strong>
            </Text>
          
            <Text textAlign="center" mt={5} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("activ_func_aviso")}
            </Text>
  
            <div className="formField">
              <input
                type="email"
                id="email"
                className="formFieldInput"
                placeholder={i18n.t("intro_email")}
                name="email"
                value={email}
                required
                onChange={e => setEmail(e.target.value)}
                //value={this.state.email}
                //onChange={this.handleChange}
    
    
              />
            </div>
  
            <Flex marginTop={15}>
            <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
          onClick= {(e) =>
           {
            sendEmail(e)
            //console.log("buscar!!")
            setshowPopPup(false)
           }
           }
           style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
           {i18n.t("subs_mayus")}</button>
           </Flex>   
           <Text  as='u' textAlign="center" paddingTop="10px" fontSize="12" textColor="black" fontFamily="Tahoma" 
           _hover={{ cursor: "pointer" }} fontWeight="normal" 
           onClick= {() =>
            {
   
             //console.log("buscar!!")
             setshowPopPup(false)
            }
            }> 
            {i18n.t("no_gracias")}
            </Text>
  
           </VStack>
          </div>
        </div>
        </Box>}


      <Flex p={2} backgroundColor="#FFFFFF" maxHeight="80px" flexDirection="row" justifyContent="space-between">
        
      <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall.png`} h="58px" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { sendEventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/home') 
        }}
       />   
       <Menu>
 <MenuButton as={Button}>
   -
 </MenuButton>
 {!glovar.USER_LOADED&&<MenuList>
  {SHOW_WEBAPP&&<MenuItem  onClick= {() => {
           //try { sendEventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
          //if(process.env.REACT_APP_LOGIN_ALLOWED==true) 
            {
           window.location.replace('/home') 
           }
           
          }
         }>Pro1X WEB APP</MenuItem>}
         {!SHOW_WEBAPP&&<MenuItem  onClick= {() => {
           //try { sendEventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
          //if(process.env.REACT_APP_LOGIN_ALLOWED==true) 
            {
           window.location.replace('/radar') 
           }
           
          }
         }>Pro1X Radar</MenuItem>}
         
         
   <MenuItem  onClick= {() => {
           try { sendEventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
          if(process.env.REACT_APP_LOGIN_ALLOWED==true) {
           window.location.replace('/login') 
           }
           else {
           setEmail(" ")
           setshowPopPup(true);
           }
          }
         }>{i18n.t("login")}</MenuItem>
   <MenuItem onClick= {() =>
         {
          
          if(glovar.REGISTER_ONLINE==true) {
           try { sendEventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           if(process.env.REACT_APP_REGISTER_ALLOWED==true) {
           window.location.replace('/register')
           }
           else {
           setEmail(" ")
           setshowPopPup(true);
           }
           }
           else {
             try { sendEventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
             //window.location.replace('/registerApp')
             setEmail(" ")
             setshowPopPup(true);
           }
         }}>{i18n.t("signup")}</MenuItem>
 </MenuList>}
 {glovar.USER_LOADED&&<MenuList>
   <MenuItem minH='48px' 
   onClick= {() =>{ 
     try { sendEventGA("button_sup","ClickMBSupAvatar", "ClickSupMBAvatar"); } catch (exx) {} /*this.changemenu(3)*/ }
   }>
   <Avatar w={12} h={10} mr={4}></Avatar>
     <span>{glovar.USER_USERNAME}</span>
   </MenuItem>

  <MenuItem  onClick= {() => {
           //try { sendEventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
          //if(process.env.REACT_APP_LOGIN_ALLOWED==true) 
            {
           window.location.replace('/radar') 
           }
           
          }
         }>Pro1X Radar</MenuItem>

   <MenuItem onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && logout() }>{i18n.t("m_cerrarses")}</MenuItem>
 </MenuList>}
</Menu>
      

          

   </Flex>
   </Box>
   {SHOW_SEARCHBAR&&<Box  backgroundColor="#FFFFFF" mt={-8} pb={4} pl={2} alignContent="center">
    <ShowSearchBar params={2}/>
    </Box>}
   </Box>

    );
  }
  else {
  //return elements for render
  return (
   <Box minHeight="86px">

    {showPopPup&&
      <Box zIndex={3} background="red"position="absolute"
>
        <div className='popup'>
        <div className='popup_inner'>
        <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={2} pb={20} alignContent="center">
        <Text textAlign="center" fontSize="24" textColor="black" fontFamily="Tahoma" fontWeight="semibold" marginBottom={2}> 
        {i18n.t("op_no_dispo")}
          </Text>
          <Text textAlign="center" mt={10} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
          {i18n.t("quieres_activa")}  <strong>{i18n.t("subs_news")}</strong>
          </Text>
        
          <Text textAlign="center" mt={5} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
          {i18n.t("activ_func_aviso")}
          </Text>

          <div className="formField" key={keyform}>
            <input
            
              type="email"
              id="email"
              className="formFieldInput"
              placeholder={i18n.t("intro_email")}
              name="email"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
              //value={this.state.email}
              //onChange={this.handleChange}
  
  
            />
          </div>

          <Flex marginTop={15}>
          <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
        onClick= {(e) =>
         {
          sendEmail(e)
          //console.log("buscar!!")
          setshowPopPup(false)
         }
         }
         style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
         {i18n.t("subs_mayus")}</button>
         </Flex>   
         <Text  as='u' textAlign="center" paddingTop="10px" fontSize="12" textColor="black" fontFamily="Tahoma" 
         _hover={{ cursor: "pointer" }} fontWeight="normal" 
         onClick= {() =>
          {
 
           //console.log("buscar!!")
           setshowPopPup(false)
          }
          }> 
          {i18n.t("no_gracias")}
          </Text>

         </VStack>
        </div>
      </div>
      </Box>}


    <Box backgroundColor="#FFFFFF" pb={"0px"}>
      
    

    <Flex p={4} backgroundColor="#FFFFFF" maxHeight="86px">
         <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall.png`} h="62px" marginLeft="7.5pc" _hover={{ cursor: "pointer" }}
       onClick= {() =>  {
         //try { EventGA("button_sup","pslogoHomeDT", "pslogoHomeDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
         glovar.CURRENT_MENU_ID=1;
         //changemenu(1)
         window.location.replace('/home') 
         //changemenu
       }} />
          <Spacer />


          {SHOW_SEARCHBAR&&<ShowSearchBar/>}
      
          {!glovar.USER_LOADED&&SHOW_WEBAPP&&<Box>
              <button className="formWebAppButton"  _hover={{ cursor: "pointer" }} 
            onClick= {() =>  {
              try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
            
            
              window.location.replace('/home')
            
            }}
            style={{  marginLeft:"2pc",  marginRight:"1pc", width: "8pc", marginTop:"0.5pc", textAlign:"center",  alignContent:"center", fontWeight:650 }}>
            Pro1X WEB APP</button>         
                    
            </Box>}

           <Spacer />


           <HStack fontSize="2xl" spacing="8" marginRight="8pc">
           
           
             {glovar.USER_LOADED&&<HStack spacing="6">
           <Avatar w={12} h={10}
            onClick= {() =>{ try { //EventGA("button_sup","ClickSupAvatar", "ClickSupAvatar"); 
            } catch (exx) {} this.changemenu(3) }}></Avatar>
              <Text _hover={{ cursor: "pointer" }} 
                    style={{ color: 'black', fontSize: 18, fontWeight:500 }} 
                    onClick= {() =>{ try { //EventGA("button_sup","ClickSupUsername", "ClickSupUsername"); 
                    } catch (exx) {} 
                    this.changemenu(3) }}>
                   {glovar.USER_USERNAME}
              </Text>
           <Box _hover={{ cursor: "pointer" }}>
           <div onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && logout() }>
           <FiLogOut />
          </div>
          </Box></HStack>}
            {!glovar.USER_LOADED&&(1==1)&&<Box>
              <button className="formLogInButton"  _hover={{ cursor: "pointer" }} 
            onClick= {() =>  {
              try { //EventGA("button_sup","pressbtn_login", "pressbtn_login"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
            //console.log("tu puta madre1:"+process.env.REACT_APP_LOGIN_ALLOWED)
            if(process.env.REACT_APP_LOGIN_ALLOWED==1) {
              console.log("entro en login")
              window.location.replace('/login')
            }
            else { 
              setEmail(" ")
              setshowPopPup(true);
             }
            }}
            style={{  marginRight:"1pc", width: "7pc", textAlign:"center", fontWeight:600 }}>
            {i18n.t("login")}</button>         
            <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
           onClick= {() => {
            if(glovar.REGISTER_ONLINE==true) 
            {
            try { //EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline");
           } catch (exx) { /*console.log("petada event:"+exx)*/}

           if(process.env.REACT_APP_REGISTER_ALLOWED==true) {
            window.location.replace('/register')
           }
           else {
            setEmail(" ")
            setshowPopPup(true);
           }
            }
            else {
              try { //EventGA("button_sup","pressbtn_register", "pressbtn_register"); 
            } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            } }
            }
            style={{ width: "7pc",  textAlign:"center"}}>
            {i18n.t("signup")}</button>          
            </Box>}
  
             
         </HStack>

          {1==2&&<HStack fontSize="2xl" spacing="8" marginRight="8pc">
        
        

         <Text fontSize="medium" fontWeight="semibold"  _hover={{ cursor: "pointer" }}  onClick= {() => {
           //try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           //window.location.replace('/login') 
           setEmail(" ")
           setshowPopPup(true);
          }
         }>{i18n.t("login")}</Text>


        <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
         onClick= {() => {
          setEmail(" ")
          setshowPopPup(true);
          
          
          if(glovar.REGISTER_ONLINE==true) {
            try { sendEventGA("button_sup","pressbtn_regonline", "pressbtn_regonline3"); } catch (exx) { }
            //window.location.replace('/register')
            }
          else {
              try { sendEventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) {}
              //window.location.replace('/registerApp')
          }
         }
         }
          style={{ width: "10pc", fontWeight:"450", textAlign:"center"}}>
          {i18n.t("signup")}</button>    

        
              
          </HStack>}
    </Flex>

   

   
   </Box> 
   </Box>
  );
        }

}
