import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import AddContentApp from './components/content/AddContentApp';
import glovar from './components/content/glovar';
//import {Helmet} from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById('root'));

try {
//if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
if((
   //window.location.href=="http://"+glovar.HOME_URL ||
   //window.location.href=="https://"+glovar.HOME_URL ||
   //window.location.href=="http://"+glovar.HOME_URL+"/" ||
   //window.location.href=="https://"+glovar.HOME_URL+"/" ||
   //window.location.href==("http://"+glovar.HOME_URL+"/login") ||
   //window.location.href==("https://"+glovar.HOME_URL+"/login") ||
   window.location.href==("http://"+glovar.HOME_URL+"/registerApp") ||
   window.location.href==("https://"+glovar.HOME_URL+"/registerApp") ||
   window.location.href==("http://"+glovar.HOME_URL+"/forgotPassword")  ||
   window.location.href==("https://"+glovar.HOME_URL+"/forgotPassword") )
  && 
  (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
{

 
  

  root.render(
    <React.StrictMode>
      <ChakraProvider>
      <AddContentApp mode={4}></AddContentApp>
      </ChakraProvider>
    </React.StrictMode>
  );
}
else {
 

root.render(
  
    <ChakraProvider>
    <App />
    </ChakraProvider>
  
);
}
}
catch(exx) {
  root.render(
    <React.StrictMode>
      <ChakraProvider>
      <App />
      </ChakraProvider>
    </React.StrictMode>
  );
}

/*root.render(
 
    <ChakraProvider>
    <App />
    </ChakraProvider>
 
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
