import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
//import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
//import {auth} from './firebase'
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";
import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { Button } from "@chakra-ui/layout";
import { FiPlus } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";

import glovar from './components/content/glovar';

import { getMONEDADivisa } from  './components/content/glovar';

import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import eventBus from "./components/eventBus";

import "./App.css";
import { getToken, removeUserSession, setUserSession } from './utils/Common';

import { 
  updateCarteraSERVER,setPortfolio,setCurrent_porfolio_ED,updateFCHuserSERVER
   } from './utils/Common';

  
//import { connectFirestoreEmulator } from 'firebase/firestore'
//import {db} from './firebase'

//import { doc, updateDoc } from "firebase/firestore";

function cancelar() {
  //glovar.CURRENT_MENU_ID=3;
  eventBus.dispatch("hideAddPosTrader", { pos: 0 });
  //console.log('commmm pos:'+pos)
  } 


  

 
var edit_entrada=45.57;

var edit_titulos=45.57; 
var edit_objetivo=45.57;
var edit_stoploss=45.57;
var edit_precio=45.57;
var edit_garantia=45.57;
var edit_multi=45.57;
var edit_sentido=1;
var edit_stock=0;

var edit_fent="";
var edit_temp=0;
var edit_symbol="";
var edit_currency="";
var edit_m="";



var offTRADERPOS, offEDITPOS=0;

//function EditPosTrader({ traderPos }) {

  //function EditPosTrader() {

    async function  getRadar() {
      //console.log("yeeeeeeeeeee")
      updateCarteraSERVER();
      setPortfolio(glovar.CURRENT_PORTFOLIO);
      //console.log("despues updateCarteraServer!")
      //console.log("holaaa sunccccccccc:"+offTRADERPOS)
      //console.log("yeeeeeeeeeee")

      glovar.current_porfolio_ED.cartera = [...glovar.CURRENT_PORTFOLIO];
      //glovar.current_porfolio_ED.historial = [...glovar.CURRENT_HISTORIAL];
      setCurrent_porfolio_ED(glovar.current_porfolio_ED);
      updateFCHuserSERVER(5); //Cartera_ed

    if(1==1) {
    try 
         {
          var headers = new Headers(); 
 
          //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
          //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)
 
          headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
          headers.set('Content-Type', 'application/json');
      
          //glovar.CURRENT_USER=cartera;
          //glovar.current_porfolio_ED=cartera.cartera_ed;
                       
           //console.log(JSON.stringify(headers))
 
           var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
           carteraPARSED = carteraPARSED.replace('[', '');
           carteraPARSED = carteraPARSED.replace(']', '');
 
           /*
           var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
           historialPARSED = historialPARSED.replace('[', '');
           historialPARSED = historialPARSED.replace(']', '');
           */
           /*
           var favoritosPARSED=JSON.stringify(glovar.CURRENT_FAVORITOS);
           favoritosPARSED = favoritosPARSED.replace('[', '');
           favoritosPARSED = favoritosPARSED.replace(']', '');
           */
 
          const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                //method: "POST",
                method: "PUT",  
                headers: headers,
               
                body: 
                JSON.stringify({ 
                 //rentab: glovar.current_porfolio_ED.rentab,
                 name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                 //idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                 //id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                 /*descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                 risk_level: glovar.current_porfolio_ED.risk_level,
                 max_level: glovar.current_porfolio_ED.max_level,
                 countriesID:glovar.current_porfolio_ED.countriesID,
                 activos: glovar.current_porfolio_ED.activos,
                 */
                 //op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                 //rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                 //sentido: glovar.current_porfolio_ED.sentido,
                 //temporal: glovar.current_porfolio_ED.temporal,
                 //historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                 cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //favoritos: favoritosPARSED,
                 //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                 //capital:glovar.current_porfolio_ED.capital,
                 //invertido:glovar.current_porfolio_ED.invertido,
                 //moneda:glovar.current_porfolio_ED.moneda,
                 //profit:glovar.current_porfolio_ED.profit,
                 //currency:glovar.current_porfolio_ED.currency,
                 //priority:glovar.current_porfolio_ED.priority,
                 //rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                 //profit_acum:glovar.current_porfolio_ED.profit_acum,

                 lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                 //lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                 //lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                 lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                 /*
                 type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                 op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                 followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                 cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                 ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                 FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
                 */
            //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
            //descript: "que pasa nen me voy de subidon!"
               })
          }) 
 
          //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
         }
         catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
       }

      /*
      var carteraPARSED=JSON.stringify(glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA);

      console.log("carteraPARSED:"+carteraPARSED)
      carteraPARSED = carteraPARSED.replace('[', '');
      carteraPARSED = carteraPARSED.replace(']', '');
      */
      //carteraPARSED = carteraPARSED.replace(/\]"/g, '');

      //console.log("glovar.TOPTRADERS_PRO1[offTRADERPOS]:"+glovar.TOPTRADERS_PRO1[offTRADERPOS].name)
      //if(1==1) return;


      /*
      const washingtonRef = doc(db, "toptraders", glovar.TOPTRADERS_PRO1[offTRADERPOS].name); //"Eva_trading");
 

 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  cartera:carteraPARSED,
  invertido:glovar.TOPTRADERS_PRO1[offTRADERPOS].invertido
 });*/


 /*#########################################################*/
  /*# Actualitzem CARTERA del TOPTRADER al servidor de BD #*/
  /*
  if(1==1) { //ya lo hemos subido una vez

    let headers = new Headers(); 
    headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_ADMIN + ":" + glovar.PASS_API_ADMIN));
    headers.set('Content-Type', 'application/json');


    const response = fetch('http://localhost:3010/api/toptraders/'+glovar.TOPTRADERS_PRO1[offTRADERPOS].id, {
          //mode: 'no-cors',
          //cache: 'no-cache',
          //credentials: 'same-origin',
          method: "PUT", // or "PUT" with the url changed to, e.g "https://reqres.in/api/users/2"
          headers: headers,
          //headers: {
          //       'Content-Type': 'application/json'
          //},
          body: 
             JSON.stringify({ 
               cartera:carteraPARSED,
               invertido:glovar.TOPTRADERS_PRO1[offTRADERPOS].invertido
               })
          }) 
          //.then(response => response.json())
          //   .then(json => {
          //         console.log("pujat correctament toptrader:"+glovar.TOPTRADERS_PRO1[j].name)
          });
         
   }*/
   /*##################################################*/
   /*##################################################*/
 
      //eventBus.dispatch("hideEditPosTrader", { pos: 0 });
      eventBus.dispatch("hideAddPosTrader", { pos: 0 });
    }

  export default ({ traderPos, editPos, cont, valor }) => {

  //editcont=cont;

  if(editPos==1) {
    //Mostro per defecte els valors de la ficha del valor
    edit_entrada=valor.regularMarketPrice;
    edit_titulos=1; 
    edit_objetivo=valor.regularMarketPrice; 
    edit_stoploss=valor.regularMarketPrice; 
    edit_precio=valor.regularMarketPrice; 
    edit_garantia=1;
    edit_multi=1;
    edit_sentido=1;
    edit_stock=valor.shortName;

    try {
    edit_fent=valor.regularMarketTime.substring(0, valor.regularMarketTime.indexOf("T"));
    }
    catch(ex) {
    edit_fent="";
    }
     
    edit_temp=Date.now(); 
    edit_symbol=valor.symbol; 
    edit_currency=valor.currency; 
    edit_m=getMONEDADivisa(valor.currency,valor.quoteType)
    /*
    console.log("VALOR:\n");
    console.log("VALOR:\n");
    console.log("VALOR:\n");
    console.log(JSON.stringify(valor))
    edit_entrada=glovar.CURRENT_PORTFOLIO[editPos].ent;

    edit_titulos=glovar.CURRENT_PORTFOLIO[editPos].acciones; 
    edit_objetivo=glovar.CURRENT_PORTFOLIO[editPos].obj; 
    edit_stoploss=glovar.CURRENT_PORTFOLIO[editPos].stop; 
    edit_precio=glovar.CURRENT_PORTFOLIO[editPos].coti; 
    edit_garantia=glovar.CURRENT_PORTFOLIO[editPos].garantia;
    edit_multi=glovar.CURRENT_PORTFOLIO[editPos].multi;
    edit_sentido=glovar.CURRENT_PORTFOLIO[editPos].sentido;
    edit_stock=glovar.CURRENT_PORTFOLIO[editPos].stock;

    edit_fent="12-11-24"; 
    edit_temp=Date.now(); 
    edit_symbol=" "; 
    edit_currency="USD"; 
    edit_m="$"; 
    */
  }
  else 
  {

   edit_entrada=0;
   edit_titulos=0; 
   edit_objetivo=0;
   edit_stoploss=0; 
   edit_precio=0;
   edit_garantia=1;
   edit_multi=1;
   edit_sentido=1;
   edit_stock="Nombre del activo";

   edit_fent="12-11-24"; 
   edit_temp=Date.now(); 
   edit_symbol="TICKER"; 
   edit_currency="USD"; 
   edit_m="$"; 
  }


  //console.log('traderPos:'+traderPos)
  //console.log('traderPos:'+editPos)

  offTRADERPOS=traderPos;
  offEDITPOS=editPos;


  //console.log("cacaton23: "+JSON.stringify(glovar.CURRENT_PORTFOLIO[editPos]));



  //{"CarteraID":"0","opID":"100","stockID":"1000","stock":"Allianz","Benf":"456.30","rent":"4.95","ent":"205","fent":"19-07-21","temp":"1604422800",
  //"obj":"150","stop":"210","idPOS":"0","acciones":"45","status":"0","sal":"90","fsal":"23-4-2020","tempsal":"1596992669",
  //"coti":194.86,"sentido":"2","garantia":"1","multi":"1","currency":"EUR","m":"€","symbol":"ALV.DE"}

  


 //console.log('edit_entrada:'+edit_entrada);

 
 
  const [entrada, setEntrada] = useState(edit_entrada)
  const [titulos, setTitulos] = useState(edit_titulos) 
  const [objetivo, setObjetivo] = useState(edit_objetivo)
  const [stoploss, setStoploss] = useState(edit_stoploss) 
  const [precio, setPrecio] = useState(edit_precio)
  const [garantia, setGarantia] = useState(edit_garantia) 
  const [multi, setMulti] = useState(edit_multi)
  const [sentido, setSentido] = useState(edit_sentido) 
  const [stock, setStock] = useState(edit_stock) 

  const [fent, setFent] = useState(edit_fent)
  const [temp, setTemp] = useState(edit_temp)
  const [symbol, setSymbol] = useState(edit_symbol)
  const [currency, setCurrency] = useState(edit_currency)
  const [m, setM] = useState(edit_m)

  /*
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  //const {setTimeActive} = useAuthValue()
  const navigate = useNavigate()
  */

  /*useEffect(() => {
    //if(glovar.FORCE_REFRESH_ELEMENT==1) 
    {
    setEntrada(edit_entrada); // This will always use latest value of count
    }
}, [edit_entrada]);*/

  useEffect(() => { setEntrada(edit_entrada); }, [edit_entrada]);
  useEffect(() => { setTitulos(edit_titulos); }, [edit_titulos]);
  useEffect(() => { setObjetivo(edit_objetivo); }, [edit_objetivo]);
  useEffect(() => { setStoploss(edit_stoploss); }, [edit_stoploss]);
  useEffect(() => { setPrecio(edit_precio); }, [edit_precio]);
  useEffect(() => { setGarantia(edit_garantia); }, [edit_garantia]);
  useEffect(() => { setMulti(edit_multi); }, [edit_multi]);
  useEffect(() => { setSentido(edit_sentido); }, [edit_sentido]);
  useEffect(() => { setStock(edit_stock); }, [edit_stock]);


  useEffect(() => { setFent(edit_fent); }, [edit_fent]);
  useEffect(() => { setTemp(edit_temp); }, [edit_temp]);
    useEffect(() => { setSymbol(edit_symbol); }, [edit_symbol]);
  useEffect(() => { setCurrency(edit_currency); }, [edit_currency]);
    useEffect(() => {  setM(edit_m); }, [edit_m]);




  //console.log('entrada:'+entrada)

  

  const login = e => {
    //const login = async (e) => {
      
    e.preventDefault()
    

    /*
    console.log("hooaaaa")
    console.log('0entrada:'+entrada);
    console.log('0titulos:'+titulos);
    console.log('objetivo:'+objetivo);
    console.log('stoploss:'+stoploss);
    console.log('precio:'+precio);
    console.log('garantia:'+garantia);
    console.log('multi:'+multi);
    console.log('sentido:'+sentido);
    console.log("adiosss")
    */
    var cartera = {
     CarteraID:0,
     opID:100,
     stockID:1000,
     stock:"",
     Benf:0,
     rent:0,
     ent:0,
     entrada:0,
     fent:"00-00-00",
     temp:"1000000000",
     obj:0,
     stop:0,
     idPOS:0,
     acciones:0,
     status:0,
     sal:0,
     fsal:"00-00-00",
     tempsal:"1000000000",
     coti:0,
     sentido:1,
     garantia:1,
     multi:1,
     currency:"EUR",
     m:"€",
     symbol:""

    };


    cartera.acciones=titulos; 

    cartera.ent=entrada;
    cartera.entada=entrada;
    cartera.obj=objetivo; 
    cartera.stop=stoploss; 
    cartera.coti=precio; 
    cartera.garantia=garantia;
    cartera.multi=multi;
    cartera.sentido=sentido;

    cartera.stock=stock;

    cartera.fent=fent;
    cartera.temp=temp;
    cartera.symbol=symbol;
    cartera.currency=currency;
    cartera.m=m;


    /*
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].acciones=titulos; 

    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].ent=entrada;

    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].obj=objetivo; 
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].stop=stoploss; 
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].coti=precio; 
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].garantia=garantia;
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].multi=multi;
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].sentido=sentido;

    glovar.CURRENT_PORTFOLIO[editPos].stock=stock;

    glovar.CURRENT_PORTFOLIO[editPos].fent=fent;
    glovar.CURRENT_PORTFOLIO[editPos].temp=temp;
    glovar.CURRENT_PORTFOLIO[editPos].symbol=symbol;
    glovar.CURRENT_PORTFOLIO[editPos].currency=currency;
    glovar.CURRENT_PORTFOLIO[editPos].m=m;
    */

    glovar.CURRENT_PORTFOLIO.push(cartera);

    try 
    {
     var total_invertido=0;
     var invertido=0;

     for (let i = 0; i <glovar.CURRENT_PORTFOLIO.length; i++) 
     {

      let MULTIPLICADOR_DIVISA=1;

      //Solo nos funcionará con $ y con Libras pq tenemos en cartera personal y es lo que provoca que consultemos las divisas.
      //Habría que encontrar una solución
      //[{"symbol":"USDEUR=X","currency":"USD","price":0.9592},{"symbol":"GBPEUR=X","currency":"GBP","price":1.17812}]

     
      try 
      {
        //console.log(i+"glovar.TOPTRADERS_PRO1[traderPos].currency:"+glovar.TOPTRADERS_PRO1[traderPos].currency);
        //console.log(i+"glovar.CURRENT_PORTFOLIO[i].currency:"+glovar.CURRENT_PORTFOLIO[i].currency);

       //Si no lo es, le aplicamos el multiplicador en función de la divisa por defecto.
       //if(glovar.TOPTRADERS_PRO1[traderPos].currency!=glovar.CURRENT_PORTFOLIO[i].currency)
       if(glovar.current_porfolio_ED.currency!=glovar.CURRENT_PORTFOLIO[i].currency)
       {
        for (let j = 0; j < glovar.GLOBAL_CURRENT_PARTDIVISAS.length; j++) 
        {
         //updateamos coti 
         try 
         {
          if(glovar.GLOBAL_CURRENT_PARTDIVISAS[j].currency.toUpperCase()==glovar.CURRENT_PORTFOLIO[i].currency.toUpperCase())
          { 
           MULTIPLICADOR_DIVISA= glovar.GLOBAL_CURRENT_PARTDIVISAS[j].price;
           //console.log('MULTIPLICADOR_DIVISA:'+MULTIPLICADOR_DIVISA)
           j=glovar.GLOBAL_CURRENT_PARTDIVISAS.length;
          }
         }
         catch(Exxx3) {}
        }
       }
      }
      catch(Exppp) { }



       total_invertido=total_invertido+
       (MULTIPLICADOR_DIVISA*glovar.CURRENT_PORTFOLIO[i].coti*glovar.CURRENT_PORTFOLIO[i].acciones)
       //console.log('glovar.HISTORIAL_LOCAL1[i].rent:',glovar.HISTORIAL_LOCAL1[i].rent)
       //var r1=parseFloat(INFO_HISTORIAL[i].rent)
       //console.log('r1:'+r1)
       //rentACUM=parseFloat(rentACUM+r1);
       //console.log('rentACUM:',rentACUM)
     }
     //glovar.TOPTRADERS_PRO1[traderPos].invertido=parseFloat(total_invertido/glovar.TOPTRADERS_PRO1[traderPos].capital)*100;
     glovar.current_porfolio_ED.invertido=parseFloat(total_invertido/glovar.current_porfolio_ED.capital)*100;
    }
    catch(Exxx) {}

    getRadar(offTRADERPOS);

    offTRADERPOS=traderPos;
    offEDITPOS=editPos;

    //var CARTERA_INFO_CARTERA_TEXT='['+glovar.TOPTRADERS_PRO1[offTRADERPOS].cartera_text+']';
    //console.log('##CCARTERA_INFO_CARTERA_TEXT:'+CARTERA_INFO_CARTERA_TEXT);
     
    //var CARTERA_INFO_CARTERA = JSON.parse(CARTERA_INFO_CARTERA_TEXT);

    //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].acciones=titulos; 
    /*
 edit_objetivo=glovar.CURRENT_PORTFOLIO[editPos].obj; 
 edit_stoploss=glovar.CURRENT_PORTFOLIO[editPos].stop; 
 edit_precio=glovar.CURRENT_PORTFOLIO[editPos].coti; 
 edit_garantia=glovar.CURRENT_PORTFOLIO[editPos].garantia;
 edit_multi=glovar.CURRENT_PORTFOLIO[editPos].multi;
 edit_sentido=glovar.CURRENT_PORTFOLIO[editPos].sentido;
 edit_stock=glovar.CURRENT_PORTFOLIO[editPos].stock;

 */

 


/*
 const washingtonRef = doc(db, "toptraders", "Eva_trading");
 
 async () => {
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
 });
 }
 */
 /*
 var washingtonRef = db.collection("toptraders").doc("Eva_trading");

 const cityRef = db.collection('cities').doc('DC');

// Set the 'capital' field of the city
const res = await cityRef.update({capital: true});*/

 /*

// Set the "capital" field of the city 'DC'
return washingtonRef.update({
  cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
})
.then(() => {
    console.log("Document successfully updated!");
})
.catch((error) => {
    // The document probably doesn't exist.
    console.error("Error updating document: ", error);
});
*/

    /*
    try {
      var db = firebase.firestore();
   
      let NOM_USER_FB=glovar.USER_USERNAME;
      let COLLECTION_FB="users";
      
      //if(glovar.MODE_USER==0) {NOM_USER_FB=glovar.ANOM_USER_USERNAME; COLLECTION_FB='users_anom';} 
      
      db.collection("toptraders").doc("Eva_trading").update({
        //historial: glovar.HISTORIAL_LOCAL1,
        cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
        //numFavoritos: glovar.NUM_FAVORITOS
    })
    .catch(function(error) {
      //console.log('ERROR UPLOAD CARTERA SERVER:'+error);  
    }).then(function() {
      //console.log("###UUPLOAD CARTERA SERVER");
      }
     );
    }
    catch(exxx) {
      //console.log('Petada ERROR UPLOAD carera SERVER:'+exxx);  
    }
    */
   
    
    //glovar.CURRENT_MENU_ID=3;
    //eventBus.dispatch("hideEditPosTrader", { pos: 0 });
    //console.log('commmm pos:'+pos)
    } 
  

 
  return (
    <Box ml={8} mr={8}>
   
    
   
    
    <form className="formFields" onSubmit={login}>

    <input style={{fontSize:"1.2em", fontWeight:"bold", marginBottom:"1pc", marginTop:"-0.3pc", textAlign:"center", width:"96%" }}
     value={stock}
     onChange={e => setStock(e.target.value)}
    
    />


      <HStack marginBottom="1.2pc" justifyContent="space-between">
        <div style={{ width: "8pc", marginRight:"10px", textAlign:"center",  }}>
          <label className="formFieldLabel" htmlFor="email">
            Entrada
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="entrada"
            placeholder={edit_entrada}
            name="entrada"
            value={entrada}
            //required
            onChange={e => setEntrada(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>
       
       

        <div className="formField"    style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Títulos
          </label>
          <input
          style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="titulos"
            placeholder={edit_titulos}
            name="titulos"
            value={titulos}
            //required
            onChange={e => setTitulos(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>

        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Objetivo
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="objetivo"
            placeholder={edit_objetivo}
            name="objetivo"
            value={objetivo}
            //required
            onChange={e => setObjetivo(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>


        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Stop
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="stoploss"
            placeholder={edit_stoploss}
            name="stoploss"
            value={stoploss}
            //required
            onChange={e => setStoploss(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>

        </HStack>


        <HStack marginBottom="1.2pc" justifyContent="space-between">
        <div style={{ width: "8pc", marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Precio
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="precio"
            placeholder={edit_precio}
            name="precio"
            value={precio}
            //required
            onChange={e => setPrecio(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>
       
       

        <div className="formField"    style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Garantia
          </label>
          <input
          style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="garantia"
            placeholder={edit_garantia}
            name="garantia"
            value={garantia}
            //required
            onChange={e => setGarantia(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>

        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Multi
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="multi"
            placeholder={edit_multi}
            name="multi"
            value={multi}
            //required
            onChange={e => setMulti(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>


        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
           Sentido
          </label>
          <select  style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
          value={sentido}
          onChange={e => setSentido(e.target.value)}
            >
            <option value={1}>Alcista</option>
            <option value={2}>Bajista</option>
          </select>
        </div>

        </HStack>


      



        <HStack marginBottom="1.2pc" justifyContent="space-between">
        <div style={{ width: "8pc", marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            F.Entrada
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="fent"
            placeholder={edit_fent}
            name="fent"
            value={fent}
            //required
            onChange={e => setFent(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>
       
       
       
              
        {1==2&&<div className="formField"    style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Time Entrada
          </label>
          <input
          style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="temp"
            placeholder={edit_temp}
            name="temp"
            value={temp}
            //required
            onChange={e => setTemp(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>}



        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Ticker
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="symbol"
            placeholder={edit_symbol}
            name="symbol"
            value={symbol}
            //required
            onChange={e => setSymbol(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>


      

<div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Currency
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="currency"
            placeholder={edit_currency}
            name="currency"
            value={currency}
            //required
            onChange={e => setCurrency(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>

        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Moneda
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="m"
            placeholder={edit_m}
            name="m"
            value={m}
            //required
            onChange={e => setM(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>
        
        </HStack>


        {1==2&&<HStack marginBottom="0pc" justifyContent="center">
      

        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Moneda
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="m"
            placeholder={edit_m}
            name="m"
            value={m}
            //required
            onChange={e => setM(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>


        </HStack>}



        <div style={{  maxHeight:"10px", flex:1, textAlign:"center", alignContent:"flex-end"  }}>
        <HStack>
        
          <button className="formFieldOutlineButton"  _hover={{ cursor: "pointer" }} 
          onClick={() => cancelar()}
          style={{  flex:1, marginLeft:"4pc", marginRight:"2pc", width: "14pc", textAlign:"center", fontWeight:700 }}>
          CANCELAR</button>
          
          <button className="formFieldFilledButton"  _hover={{ cursor: "pointer" }} 
         
          style={{ flex:1, marginRight:"6pc", width: "14pc",  textAlign:"center" }}>
          AÑADIR A CARTERA</button>
          
        </HStack>
        </div>
        

     
      
       
      </form>
    
    
    </Box>
  );
}

//export default EditPosTrader