

import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout"; 
import { VStack } from "@chakra-ui/layout"; 
import { Image } from "@chakra-ui/image";
import glovar from "./glovar";
import i18n from "../../i18n";

import { useEffect, useRef } from "react";

//import { AdComponent728, AdComponent468 } from '../Common';
  
function AdComponent468() {
  const banner = useRef();

  const atOptions = {
    key: glovar.KEY_ADCOMP_468,
    format: "iframe",
    height: 60,
    width: 468,
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}

export function AdComponent320() {
  const banner = useRef();


  const atOptions = {
    key:  glovar.KEY_ADCOMP_320,
    format: "iframe",
    height: 50,
    width: 320,
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}


  export default ({ mobile }) => {


    var VERS_MOBILE=0;
    try { if(mobile==1) { VERS_MOBILE=1; } } catch(exx) {}

    var current_diff=0;
    var diff=current_diff;
    
    let color1="#1FB31F"
    let color2="#FFFFFF"

    //var subtitle="subtitle"
    
    /*
    var percent=Number(diff).toFixed(2);
    var change=Number(diff).toFixed(2);
    var loss=Number(diff).toFixed(2);
    //var coti=price;
    var loss=(diff<0);
    */

    var current_cartera=0;
    var current_porcent=0;

    var capital_total=0;
    var invertido=0;
    var profit_loss=0;
    var rentabilidad=0;

    var moneda="€"

    try {
    if(glovar.MODE_USER>=1 && glovar.current_porfolio_ED) {

      console.log("HOMEFRONT USER glovar.current_porfolio_ED.invertido:"+glovar.current_porfolio_ED.invertido);

      capital_total=glovar.current_porfolio_ED.capital;
      invertido=Number(glovar.current_porfolio_ED.invertido).toFixed(2);
      if(glovar.current_porfolio_ED.profit_acum>0) { profit_loss=Number(glovar.current_porfolio_ED.profit_acum).toFixed(2); }
      else {
        profit_loss=0;
      }
      rentabilidad=Number(glovar.current_porfolio_ED.rentab_acum).toFixed(2);
  
      moneda=glovar.current_porfolio_ED.moneda;
      //console.log(process.env)
    }
    } catch(Exxx) {}

    try { current_cartera=Number(glovar.current_porfolio_ED.currentmoney).toFixed(2); }  catch(Exxx) {}

    try { current_diff=Number(glovar.current_porfolio_ED.profit).toFixed(2); }  catch(Exxx) {}
    
    try { current_porcent=Number(glovar.current_porfolio_ED.rentab).toFixed(2); }  catch(Exxx) {}
 
    //const icon = () => { return(<FiArrowDown />); }

    //if(diff>=0) {
      if(current_diff>=0) {
        color1="#1FB31F"
          color2="#FFFFFF"
    }
    else {
        color1="#D61A18"
        color2="#FFFFFF"
    }

    var marginTopIcons="10px";
    if(glovar.MODE_USER>=2) {
      marginTopIcons="40px";
    }

    var mtop=6;
    var mtop2=0;
    var fontsizecapital="46"

    if(VERS_MOBILE==1) {
      mtop=2;
      mtop2=-6;
      marginTopIcons="30px";
      fontsizecapital="42"
    }
    else {
    marginTopIcons="40px";
    }

    return (
        
      <div>
    
   
     
      <VStack spacing={-2}>
      
      
      <Text
      mt={mtop}
       fontSize="16"
       textColor="black"
       fontWeight="bold"
      
      >
       {i18n.t("cartera_princ")}       
       </Text>


       <Text
       fontSize={fontsizecapital}
       textColor="black"
       fontWeight="bold"
      >
       {current_cartera}{moneda}   
       </Text>

       <Box
        borderRadius="lg"
        fontSize="15"
        fontWeight="semibold"
        textAlign="center"
        paddingTop="1px"
        w={"16pc"}
        h={"1.6pc"}
        bg={color1}
        textColor={color2}
      >
        {current_diff}{moneda}   {"       "}    ({current_porcent}%)
      </Box>


   

      <HStack w="full">
        <VStack w="25%"  spacing={0} ><Image src='assets/ops.png' w="20px" h="20px"  marginTop={marginTopIcons}/><Text fontSize="13" fontWeight="bold" textColor="gray" >{i18n.t("capi_total")}</Text>
        <Text fontSize="15" fontWeight="bold" textColor="black" >{capital_total}{moneda}</Text></VStack>
        <VStack w="25%"  spacing={0} ><Image src='assets/ops.png' w="20px" h="20px"  marginTop={marginTopIcons}/><Text fontSize="14" fontWeight="bold" textColor="gray" >{i18n.t("invertido")}</Text>
        <Text fontSize="15" fontWeight="bold" textColor="black" >{invertido}%</Text></VStack>
        <VStack w="25%" spacing={0} ><Image src='assets/ops.png' w="20px" h="20px"  marginTop={marginTopIcons}/><Text fontSize="14" fontWeight="bold" textColor="gray" >{i18n.t("profit_loss")}</Text>
        <Text fontSize="15" fontWeight="bold" textColor="black" >{profit_loss}{moneda}</Text></VStack>
        <VStack w="25%"spacing={0} ><Image src='assets/ops.png' w="20px" h="20px"  marginTop={marginTopIcons}/><Text fontSize="14" fontWeight="bold" textColor="gray" >{i18n.t("rentab")}</Text>
        <Text fontSize="15" fontWeight="bold" textColor="black" >{rentabilidad}%</Text></VStack>
        </HStack>
   </VStack>

   {(glovar.MODE_USER<2)&&
   <HStack  background="black"  marginLeft="16px" marginRight="16px" rounded={9} mt="10px" justifyContent='space-around'>
        <AdComponent320/></HStack>}

{glovar.MODE_USER>=1&&(VERS_MOBILE>=0)&&(1==2)&&<HStack marginTop="10px" marginBottom="6px" marginLeft="6px" marginRight="6px" background="#EEEEEE" textAlign="center">
<Text fontSize="13" fontWeight="semibold" textColor="black" >*{i18n.t("nota_modifs_desde_app")}*</Text>
</HStack>}
 

 </div>
    
 
    );
  };
  