import {useState, useEffect} from 'react'
import glovar from '../content/glovar';

import { getModelUser } from  './utils';

export const useFetchTodoUser = (OWN_USER,nameUser, IGNORE) => {

  /*
  console.log("useFetchTodoUser OWN_USER:"+OWN_USER);
  console.log("useFetchTodoUser nameUser:"+nameUser);
  console.log("useFetchTodoUser nameUser:"+IGNORE);

  OWN_USER=1;
  nameUser="a7";
  IGNORE=false;*/

  /*
   let isFetchingTopTraders = false;
    ];*/

   const [todoTopTraders, setTodo] = useState([]);
   const [isFetchingTopTraders, setIsFetching] = useState(true);
 
  useEffect(() => {

    if(glovar.DONT_REFRESH_FETCH_TODO_USER)
    {
      //console.log("NO ACTUALIZAMOSSS!!!")
      glovar.DONT_REFRESH_FETCH_TODO_USER=false;
      setTodo(glovar.SHOW_MY_PROFILE)
      setIsFetching(false)
    }

    else 
    {
      //console.log("si ACTUALIZAMOSSS!!!")
    var TIRAR_DE_CACHE=false;

  if(IGNORE) {}
  else {
  if(OWN_USER==1) { 

   try {    
      var SHOW_TOPTRADER_PROFILE=getModelUser(glovar.USER_USERNAME,glovar.current_porfolio_ED,
                                          glovar.CURRENT_PORTFOLIO,glovar.CURRENT_HISTORIAL,glovar.CURRENT_FAVORITOS)
      
      TIRAR_DE_CACHE=true; 
    
      setTodo(SHOW_TOPTRADER_PROFILE)
      setIsFetching(false)
     } 
  catch(Exx) {}
  }

  if(!TIRAR_DE_CACHE) 
  {
    //console.log("descargo de server!")
    let headers = new Headers(); 
    headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
  
    //glovar.URL_BACKEND_PROD
    
    fetch(glovar.URL_BACKEND_PROD+'/'+nameUser, { method:'GET', //mode: 'no-cors',
   
  headers: headers,
       //'mode': 'no-cors',
       //'headers': {
       //    'Access-Control-Allow-Origin': '*',
      // }
   }).then(response => response.json())
   .then(json => {
    //console.log("ya tenemos user!!")
     //Aqui tenemos que ordenarlo por rent.
    //try { json.sort(function(a,b){ return parseFloat(b.rentab)  - parseFloat(a.rentab);}) }
    //catch(Exxpp) {}
    
    setTodo(json)
   })
   .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });
  }
  }

}
  },[]);
 
 return { todoTopTraders, isFetchingTopTraders }
   

 //return { todoTopTraders }
 
 }
 


 