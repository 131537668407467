import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
//import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
//import {auth} from './firebase'
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";

//import glovar from './components/glovar';
import glovar from './components/content/glovar';
import eventBus from "./components/eventBus";

import "./App.css";
import { getToken, removeUserSession, setUserSession, setPortfolio, setCurrent_porfolio_ED, setHistorial } from './utils/Common';

import { 
  updateCarteraSERVER,updateFCHuserSERVER
   } from './utils/Common';


//import { connectFirestoreEmulator } from 'firebase/firestore'
//import {db} from './firebase'

//import { doc, getDoc, updateDoc } from "firebase/firestore";

function cancelar() {
  //glovar.CURRENT_MENU_ID=3;
  eventBus.dispatch("hideClosePosPortfolio", { pos: 0 });
  //console.log('commmm pos:'+pos)
  } 


  

  let timemilis=Math.floor(Date.now()/1);

var edit_entrada=45.57;

var edit_titulos=45.57; 
var edit_tit_cerrar=45.57;
var edit_stoploss=45.57;
var edit_precio=45.57;
var edit_garantia=45.57;
var edit_multi=45.57;
var edit_sentido=1;
var edit_stock=0;

var edit_fent="";
var edit_temp=0;
var edit_fsal="";
var edit_tsal=0;

var edit_symbol="";
var edit_currency="";
var edit_m="";



var offTRADERPOS, offEDITPOS=0;
var firstime=0;
//function EditPosTrader({ traderPos }) {

  //function EditPosTrader() {

 

  export default ({ traderPos, editPos, cont }) => {
    
    //const d = new Date();
    //timemilis = d.getMilliseconds();

    //if(firstime==0) 
    {
    //timemilis=""+Math.floor(Date.now()/1);
    }
    //firstime=1;

    async function  closeTrade() {

      /*
      const washingtonRef19 = doc(db, "users", glovar.USER_USERNAME);


      console.log("antes del doc")
      const docUser = await getDoc(washingtonRef19);
      console.log("ya tengo el doc!!!")
    
     
      try { glovar.APP_STATE_STATE=docUser.data().appState; } catch(Exxx) { glovar.APP_STATE_STATE="none"; }
      try { if(glovar.APP_STATE_STATE==glovar.APP_STATE_ACTIVE) {  glovar.APP_STATE_RUNNING=true; } } catch(Exxxs) {} 
    
    
      if(glovar.APP_STATE_RUNNING)
      {
        console.log('Sal de la app!!!!!!!!')
        eventBus.dispatch("hideClosePosPortfolio", { pos: 0 });
        return;
      }
      else*/ 
      {
      

      var CLOSE_TIT_CERRAR=tit_cerrar;
      var CLOSE_TITULOS=titulos;
      var CLOSE_CURRENCY=currency;

      //console.log("glovar.GLOBAL_CURRENT_PARTDIVISAS:"+JSON.stringify(glovar.GLOBAL_CURRENT_PARTDIVISAS));
      //if(1==1) { return }

      if(CLOSE_TIT_CERRAR>CLOSE_TITULOS) { CLOSE_TIT_CERRAR=CLOSE_TITULOS}

      //console.log("holaaa sunccccccccc:"+offTRADERPOS)
      //console.log("yeeeeeeeeeee")

     
      //var INFO_HISTORIAL_TEXT='['+glovar.TOPTRADERS_PRO1[offTRADERPOS].historial+']';
      //var INFO_HISTORIAL = JSON.parse(INFO_HISTORIAL_TEXT);
      var INFO_HISTORIAL=glovar.CURRENT_HISTORIAL;

      //console.log("PRIMERA POS HISTORIAL:"+JSON.stringify(INFO_HISTORIAL[0]))

    

      var CALC_BENEF=0;
      var CALC_RENT=0;


      //profit and rentability calcs
      var ALCISTA=true;

      //console.log('CERRAR ASSET')

      //Si es la divisa por defecto
      let MULTIPLICADOR_DIVISA=1;

      //Solo nos funcionará con $ y con Libras pq tenemos en cartera personal y es lo que provoca que consultemos las divisas.
      //Habría que encontrar una solución
      //[{"symbol":"USDEUR=X","currency":"USD","price":0.9592},{"symbol":"GBPEUR=X","currency":"GBP","price":1.17812}]

     
     try {
     //Si no lo es, le aplicamos el multiplicador en función de la divisa por defecto.
     //if(glovar.TOPTRADERS_PRO1[offTRADERPOS].currency!=CLOSE_CURRENCY)
     if(glovar.current_porfolio_ED.currency!=CLOSE_CURRENCY)
     {
      for (let j = 0; j < glovar.GLOBAL_CURRENT_PARTDIVISAS.length; j++) 
      {
       //updateamos coti 
       try {
        // console.log('length DIVIS:'+glovar.GLOBAL_CURRENT_PARTDIVISAS.length)
       if(glovar.GLOBAL_CURRENT_PARTDIVISAS[j].currency.toUpperCase()==CLOSE_CURRENCY.toUpperCase())
       {
         MULTIPLICADOR_DIVISA= glovar.GLOBAL_CURRENT_PARTDIVISAS[j].price;
         //console.log('ACTIVO:'+glovar.CARTERAS_LOCAL1[i].stock)
         //console.log('DIVISA CARTERA PRINCIPAL:'+glovar.CARTERAS_LOCAL1_ED.currency)
         //console.log('DIVISA Activo cartera:'+glovar.CARTERAS_LOCAL1[i].currency)
         //console.log('MULTIPLICADOR_DIVISA:'+MULTIPLICADOR_DIVISA)
         j=glovar.GLOBAL_CURRENT_PARTDIVISAS.length;
       }
      }
      catch(Exxx3) {}
     }
    }
    }
    catch(Exppp) { }
    
    //console.log("MULTIPLICADOR DIVISA:"+MULTIPLICADOR_DIVISA);
    //if(1==1) { return }

    


    try {
      if(sentido==2) { ALCISTA=false; } } catch(Excep1) {} //1 bulish, 2 bearish, 3 bullish&bearish
        
      var benef=0;
      var rent=0;
      
     
      try 
      { 
       if(ALCISTA) { benef=((precio-entrada)*CLOSE_TIT_CERRAR*multi); benef=benef*1; }//benef=parseInt(benef) }
       else  { benef=((entrada-precio)*CLOSE_TIT_CERRAR*multi); benef=benef*1;  }//benef=parseInt(benef) }
      
      } catch(Exd) {}
     
      
      try { 
       if(ALCISTA) { rent=parseFloat(multi*(((precio-entrada)/entrada)*100)); rent=rent.toFixed(2); }
       else { { rent=parseFloat(multi*(((entrada-precio)/entrada)*100)); rent=rent.toFixed(2); } }
     
     } catch(Exd) {}
 
     CALC_BENEF=benef;
     CALC_RENT=rent;
    //historialInfo.Benf=benef;
    //historialInfo.rent=rent;

  

  //#########################################//
  //update profile portfolio/history params  //
  //#########################################//

  try {
  if (isNaN(glovar.current_porfolio_ED.profit_acum)) {
    //console.log("profit_acum The value is NaN, lo reseteo a cero");
    glovar.current_porfolio_ED.profit_acum=0;
  } else {
    //console.log("profit_acum The value is a valid number:", glovar.current_porfolio_ED.profit_acum);
  }
  
  if (isNaN(glovar.current_porfolio_ED.rentab_acum)) {
    //console.log(" rentab_acum The value is NaN, lo reseteo a cero");
    glovar.current_porfolio_ED.rentab_acum=0;
  } else {
    //console.log("rentab_acum The value is a valid number:", glovar.current_porfolio_ED.rentab_acum);
  }
  }
  catch(Exx) {}

  glovar.current_porfolio_ED.op_cerradas=glovar.current_porfolio_ED.op_cerradas+1;

  //GuardoParaCartera (si profit_acum)
  glovar.current_porfolio_ED.profit_acum=glovar.current_porfolio_ED.profit_acum+(benef*MULTIPLICADOR_DIVISA);
  //glovar.TOPTRADERS_PRO1[offTRADERPOS].profit=glovar.TOPTRADERS_PRO1[offTRADERPOS].profit+(benef*MULTIPLICADOR_DIVISA);
  
  //console.log("INI +glovar.TOPTRADERS_PRO1[offTRADERPOS].profit_acum :"+glovar.TOPTRADERS_PRO1[offTRADERPOS].profit_acum);


  if(glovar.current_porfolio_ED.op_cerradas==1) {glovar.current_porfolio_ED.rentmedia_op=(rent*1); }

  else 
  { 
    
    //var r1=(rent-glovar.CARTERAS_LOCAL1_ED.rentmedia_op);
    //var r2=(r1/glovar.CARTERAS_LOCAL1_ED.op_cerradas);

    var rentACUM=parseFloat((rent*1))//MULTIPLICADOR_DIVISA));
    //console.log('ini rentACUM:',rentACUM)
    for (let i = 0; i <INFO_HISTORIAL.length; i++) 
    {
      //console.log('glovar.HISTORIAL_LOCAL1[i].rent:',glovar.HISTORIAL_LOCAL1[i].rent)
      var r1=parseFloat(INFO_HISTORIAL[i].rent)
      //console.log('r1:'+r1)
      rentACUM=parseFloat(rentACUM+r1);
      //console.log('rentACUM:',rentACUM)
    }


    glovar.current_porfolio_ED.rentmedia_op=parseFloat(rentACUM/glovar.current_porfolio_ED.op_cerradas)
   //console.log('glovar.CARTERAS_LOCAL1_ED.rentmedia_op:',glovar.CARTERAS_LOCAL1_ED.rentmedia_op)
   
  }
  //glovar.CARTERAS_LOCAL1_ED.rentmedia_op=(glovar.CARTERAS_LOCAL1_ED.profit_acum/glovar.CARTERAS_LOCAL1_ED.op_cerradas);

  //Changed 21-4-21
  //glovar.CARTERAS_LOCAL1_ED.rentab_acum=glovar.CARTERAS_LOCAL1_ED.rentab_acum+(((benef*MULTIPLICADOR_DIVISA)/glovar.CARTERAS_LOCAL1_ED.capital)*100);
  
  //GuardoParaCartera (si profit_acum)
  try { if(glovar.current_porfolio_ED.profit_acum!=0)
        {
          glovar.current_porfolio_ED.rentab_acum=
          (glovar.current_porfolio_ED.profit_acum/glovar.current_porfolio_ED.capital)*100;
        }
        else { glovar.current_porfolio_ED.rentab_acum=0; }
      }
      catch(Exxx) {}

  

  //try { historialInfo.MULTIPLICADOR_DIVISA=MULTIPLICADOR_DIVISA; } catch(Ex1) {  }

 /*
  console.log("llego hasta aqui sin petar!!!!")

  console.log("CARTERA_INFO_op_cerradas:"+glovar.current_porfolio_ED.op_cerradas);
  console.log("CARTERA_INFO_rentmedia_op:"+glovar.current_porfolio_ED.rentmedia_op);
  console.log("CARTERA_INFO_rentab_acum:"+glovar.current_porfolio_ED.rentab_acum);
   //GuardoParaCartera (si profit_acum)
  //console.log("CARTERA_INFO_profit=glovar:"+glovar.TOPTRADERS_PRO1[offTRADERPOS].profit_acum);
  console.log("CARTERA_INFO_profit=glovar:"+glovar.current_porfolio_ED.profit_acum);
  */

  //console.log("CARTERA_INFO_moneda:"+glovar.TOPTRADERS_PRO1[offTRADERPOS].moneda;

 // if(1==1) { return; }
 


  //#######################################################################//
  //TO DO: AQUI VA SUBIRLO A FIREBASE los datos actualizados de portfolio
  //######################################################################//
  /*
  const timemilis1=Math.floor(Date.now()/1);
  

  //const washingtonRef1 = doc(db, "users_anom", "1648970897074");
  const washingtonRef1 = doc(db, "users", glovar.USER_USERNAME);

  await updateDoc(washingtonRef1, {
    cartera_ed:glovar.current_porfolio_ED,
    lastDataUpdateCartera_ed:timemilis1
   });
   */

   //setCurrent_porfolio_ED(glovar.current_porfolio_ED);
   //updateFCHuserSERVER(5); //Cartera_ed


 
      var newPosHistorial = {
        HistID:0,
        opID:14,
        stockID:14,
        stock:"Alphabet",
        Benf:732.57,
        rent:9.02,
        ent:2708.39,
        sal:2952.58,
        fent:"03-08-2021",
        temp:1604941200,
        fsal:"27-12-2021",
        tsal:1608827250,
        sentido:1,
        acciones:3,
        m:"$",
        symbol:"GOOGL"
      };



      newPosHistorial.HistID=0;
      newPosHistorial.opID=14;
      newPosHistorial.stockID=14;
      newPosHistorial.stock=stock;
      newPosHistorial.Benf=CALC_BENEF;
      newPosHistorial.rent=CALC_RENT;
      newPosHistorial.ent=entrada;
      newPosHistorial.sal=precio;
      newPosHistorial.fent=fent;
      newPosHistorial.temp=temp;
      newPosHistorial.fsal=fsal;
      newPosHistorial.tsal=tsal;
      newPosHistorial.sentido=sentido;
      newPosHistorial.acciones=CLOSE_TIT_CERRAR;
      newPosHistorial.m=m;
      newPosHistorial.symbol=symbol;
      

      //first pos
      //console.log("Posiciones historial:"+glovar.CURRENT_HISTORIAL.length)
      INFO_HISTORIAL = Array.isArray(INFO_HISTORIAL) ? INFO_HISTORIAL : [];
      INFO_HISTORIAL.unshift(newPosHistorial)

      glovar.CURRENT_HISTORIAL=INFO_HISTORIAL;
      //console.log("DESP UPDATE Posiciones historial:"+glovar.CURRENT_HISTORIAL.length)
      //console.log("ACTUALIZO HISTORIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAL!")
      //console.log("glovar.CURRENT_HISTORIAL:"+JSON.stringify(glovar.CURRENT_HISTORIAL));

      glovar.SHOW_MY_PROFILE.historial = [...glovar.CURRENT_HISTORIAL];

      //if(1==1) { eventBus.dispatch("hideClosePosPortfolio", { pos: 0 }); return; }

      const timemilis=Math.floor(Date.now()/1);
      
      setHistorial(glovar.CURRENT_HISTORIAL);
      updateFCHuserSERVER(3); //Historial

       /*
      //const washingtonRef = doc(db, "users_anom", "1648970897074");
      const washingtonRef = doc(db, "users", glovar.USER_USERNAME);

      await updateDoc(washingtonRef, {
        historial:glovar.CURRENT_HISTORIAL,
        lastDataUpdateHistorial:timemilis
       });
      */
 

 var var_tit_cerrar=CLOSE_TIT_CERRAR;
 var var_titulos=CLOSE_TITULOS;

 //console.log("ini var_tit_cerrar:"+var_tit_cerrar) 
 //console.log("ini var_titulos:"+var_titulos) 

 //Borramos de la cartera pq ya hemos cerrado la totalidad de la posición
 if(var_tit_cerrar>= var_titulos)
 {
  //console.log("var_tit_cerrar:"+var_tit_cerrar) 
  //console.log("var_titulos:"+var_titulos) 
  //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA.splice(offEDITPOS, 1);

  //glovar.CURRENT_PORTFOLIO[offEDITPOS].ent="TUPUTAMADRE";

  glovar.CURRENT_PORTFOLIO.splice(offEDITPOS, 1);
  //glovar.CURRENT_PORTFOLIO = glovar.CURRENT_PORTFOLIO.filter((_, index) => index !== offEDITPOS);
  
  glovar.SHOW_MY_PROFILE.cartera = [...glovar.CURRENT_PORTFOLIO];

  //console.log(JSON.stringify(glovar.CURRENT_PORTFOLIO))
  

  
  //carteraPARSED = carteraPARSED.replace(/\]"/g, '');

  const timemilis=Math.floor(Date.now()/1);
  
  setPortfolio(glovar.CURRENT_PORTFOLIO);
  updateCarteraSERVER();

  //console.log("ACTUALIZO PORTFOLIO!!!!!")

  glovar.current_porfolio_ED.cartera = [...glovar.CURRENT_PORTFOLIO];
  glovar.current_porfolio_ED.historial = [...glovar.CURRENT_HISTORIAL];
  setCurrent_porfolio_ED(glovar.current_porfolio_ED);
  updateFCHuserSERVER(5); //Cartera_ed

  if(1==1) {
    try 
         {
          var headers = new Headers(); 
 
          //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
          //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)
 
          headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
          headers.set('Content-Type', 'application/json');
      
          //glovar.CURRENT_USER=cartera;
          //glovar.current_porfolio_ED=cartera.cartera_ed;
                       
           //console.log(JSON.stringify(headers))
 
           var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
           carteraPARSED = carteraPARSED.replace('[', '');
           carteraPARSED = carteraPARSED.replace(']', '');
 
           var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
           historialPARSED = historialPARSED.replace('[', '');
           historialPARSED = historialPARSED.replace(']', '');
 
           /*
           var favoritosPARSED=JSON.stringify(glovar.CURRENT_FAVORITOS);
           favoritosPARSED = favoritosPARSED.replace('[', '');
           favoritosPARSED = favoritosPARSED.replace(']', '');
           */
 
          const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                //method: "POST",
                method: "PUT",  
                headers: headers,
               
                body: 
                JSON.stringify({ 
                 //rentab: glovar.current_porfolio_ED.rentab,
                 name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                 //idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                 //id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                 /*descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                 risk_level: glovar.current_porfolio_ED.risk_level,
                 max_level: glovar.current_porfolio_ED.max_level,
                 countriesID:glovar.current_porfolio_ED.countriesID,
                 activos: glovar.current_porfolio_ED.activos,
                 */
                 op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                 rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                 //sentido: glovar.current_porfolio_ED.sentido,
                 //temporal: glovar.current_porfolio_ED.temporal,
                 historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                 cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //favoritos: favoritosPARSED,
                 //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                 //capital:glovar.current_porfolio_ED.capital,
                 //invertido:glovar.current_porfolio_ED.invertido,
                 //moneda:glovar.current_porfolio_ED.moneda,
                 //profit:glovar.current_porfolio_ED.profit,
                 //currency:glovar.current_porfolio_ED.currency,
                 //priority:glovar.current_porfolio_ED.priority,
                 rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                 profit_acum:glovar.current_porfolio_ED.profit_acum,

                 lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                 //lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                 lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                 lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                 /*
                 type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                 op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                 followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                 cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                 ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                 FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
                 */
            //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
            //descript: "que pasa nen me voy de subidon!"
               })
          }) 
 
          //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
         }
         catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
       }
  
/*
  //const washingtonRef = doc(db, "users_anom", "1648970897074");
    const washingtonRef = doc(db, "users", glovar.USER_USERNAME);
await updateDoc(washingtonRef, {
  cartera:glovar.CURRENT_PORTFOLIO,
  lastDataUpdatePortfolio:timemilis
 });
  */

 }
 //Editamos la posición con los títulos que queden
 else {
  glovar.CURRENT_PORTFOLIO[offEDITPOS].acciones=(CLOSE_TITULOS-CLOSE_TIT_CERRAR); 


  //var carteraPARSED=JSON.stringify(glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA);
  //carteraPARSED = carteraPARSED.replace('[', '');
  //carteraPARSED = carteraPARSED.replace(']', '');
  //carteraPARSED = carteraPARSED.replace(/\]"/g, '');

  const timemilis=Math.floor(Date.now()/1);
  console.log("CERRAR PARCIAL!!!!!!!!!!!!!!!!!!!")

  setPortfolio(glovar.CURRENT_PORTFOLIO);
  updateCarteraSERVER();

  glovar.current_porfolio_ED.cartera = [...glovar.CURRENT_PORTFOLIO];
  glovar.current_porfolio_ED.historial = [...glovar.CURRENT_HISTORIAL];
  setCurrent_porfolio_ED(glovar.current_porfolio_ED);
  updateFCHuserSERVER(5); //Cartera_ed

  if(1==1) {
    try 
         {
          var headers = new Headers(); 
 
          //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
          //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)
 
          headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
          headers.set('Content-Type', 'application/json');
      
          //glovar.CURRENT_USER=cartera;
          //glovar.current_porfolio_ED=cartera.cartera_ed;
                       
           //console.log(JSON.stringify(headers))
 
           var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
           carteraPARSED = carteraPARSED.replace('[', '');
           carteraPARSED = carteraPARSED.replace(']', '');
 
           var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
           historialPARSED = historialPARSED.replace('[', '');
           historialPARSED = historialPARSED.replace(']', '');
 
           /*
           var favoritosPARSED=JSON.stringify(glovar.CURRENT_FAVORITOS);
           favoritosPARSED = favoritosPARSED.replace('[', '');
           favoritosPARSED = favoritosPARSED.replace(']', '');
           */
 
          const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                //method: "POST",
                method: "PUT",  
                headers: headers,
               
                body: 
                JSON.stringify({ 
                 //rentab: glovar.current_porfolio_ED.rentab,
                 name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                 //idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                 //id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                 /*descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                 risk_level: glovar.current_porfolio_ED.risk_level,
                 max_level: glovar.current_porfolio_ED.max_level,
                 countriesID:glovar.current_porfolio_ED.countriesID,
                 activos: glovar.current_porfolio_ED.activos,
                 */
                 op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                 rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                 //sentido: glovar.current_porfolio_ED.sentido,
                 //temporal: glovar.current_porfolio_ED.temporal,
                 historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                 cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //favoritos: favoritosPARSED,
                 //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                 //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                 //capital:glovar.current_porfolio_ED.capital,
                 //invertido:glovar.current_porfolio_ED.invertido,
                 //moneda:glovar.current_porfolio_ED.moneda,
                 //profit:glovar.current_porfolio_ED.profit,
                 //currency:glovar.current_porfolio_ED.currency,
                 //priority:glovar.current_porfolio_ED.priority,
                 rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                 profit_acum:glovar.current_porfolio_ED.profit_acum,

                 lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                 //lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                 lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                 lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                 /*
                 type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                 op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                 followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                 cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                 ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                 FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
                 */
            //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
            //descript: "que pasa nen me voy de subidon!"
               })
          }) 
 
          //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
         }
         catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
       }
  
  /*

  //const washingtonRef = doc(db, "users_anom", "1648970897074");
    const washingtonRef = doc(db, "users", glovar.USER_USERNAME);
  await updateDoc(washingtonRef, {
    cartera:glovar.CURRENT_PORTFOLIO,
    lastDataUpdatePortfolio:timemilis
   });
  */
 }
  

  eventBus.dispatch("hideClosePosPortfolio", { pos: 0 });
}
    
eventBus.dispatch("hideClosePosPortfolio", { pos: 0 });
  }
  
  //editcont=cont;

  //console.log('traderPos:'+traderPos)
  //console.log('traderPos:'+editPos)

  
  offTRADERPOS=traderPos;
  offEDITPOS=editPos;


  //console.log("cacaton23: "+JSON.stringify(glovar.CURRENT_PORTFOLIO[editPos]));



  //{"CarteraID":"0","opID":"100","stockID":"1000","stock":"Allianz","Benf":"456.30","rent":"4.95","ent":"205","fent":"19-07-21","temp":"1604422800",
  //"obj":"150","stop":"210","idPOS":"0","acciones":"45","status":"0","sal":"90","fsal":"23-4-2020","tempsal":"1596992669",
  //"coti":194.86,"sentido":"2","garantia":"1","multi":"1","currency":"EUR","m":"€","symbol":"ALV.DE"}

  edit_entrada=glovar.CURRENT_PORTFOLIO[editPos].ent;

 edit_titulos=glovar.CURRENT_PORTFOLIO[editPos].acciones; 
 edit_tit_cerrar=glovar.CURRENT_PORTFOLIO[editPos].acciones; 
 edit_stoploss=glovar.CURRENT_PORTFOLIO[editPos].stop; 
 edit_precio=glovar.CURRENT_PORTFOLIO[editPos].coti; 
 edit_garantia=glovar.CURRENT_PORTFOLIO[editPos].garantia;
 edit_multi=glovar.CURRENT_PORTFOLIO[editPos].multi;
 edit_sentido=glovar.CURRENT_PORTFOLIO[editPos].sentido;
 edit_stock=glovar.CURRENT_PORTFOLIO[editPos].stock;

 edit_fent=glovar.CURRENT_PORTFOLIO[editPos].fent; 
 edit_temp=glovar.CURRENT_PORTFOLIO[editPos].temp; 

 edit_fsal=glovar.CURRENT_PORTFOLIO[editPos].fsal; 

 
 edit_tsal=timemilis;//glovar.CURRENT_PORTFOLIO[editPos].tsal; 

 try {
  var date = new Date(); //Current Date
  //var day = date.getDate();
  //var month = new Date().getMonth() + 1; //Current Month
  var month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
  var day = ((date.getDate()) < 10 ? '0' : '') + (date.getDate());
  var year = new Date().getFullYear(); //Current Year
  year=year+'';
  year=year.substring(2,4);
  //var hours = new Date().getHours(); //Current Hours
  //var min = new Date().getMinutes(); //Current Minutes
  //var sec = new Date().getSeconds(); //Current Seconds

  edit_fsal=day+'-'+month+"-"+year;
  edit_tsal=Math.round((new Date()).getTime() / 2)*2;
}
catch(eeex) {/*console.log('petada:',eeex)*/}


 var edit_symbol=glovar.CURRENT_PORTFOLIO[editPos].symbol; 
 var edit_currency=glovar.CURRENT_PORTFOLIO[editPos].currency; 
 var edit_m=glovar.CURRENT_PORTFOLIO[editPos].m; 


 //console.log('edit_entrada:'+edit_entrada);

 
 
  const [entrada, setEntrada] = useState(edit_entrada)
  const [titulos, setTitulos] = useState(edit_titulos) 
  const [tit_cerrar, setTitCerrar] = useState(edit_tit_cerrar)
  const [stoploss, setStoploss] = useState(edit_stoploss) 
  const [precio, setPrecio] = useState(edit_precio)
  const [garantia, setGarantia] = useState(edit_garantia) 
  const [multi, setMulti] = useState(edit_multi)
  const [sentido, setSentido] = useState(edit_sentido) 
  const [stock, setStock] = useState(edit_stock) 

  const [fent, setFent] = useState(edit_fent)
  const [temp, setTemp] = useState(edit_temp)
  const [fsal, setFsal] = useState(edit_fsal)
  const [tsal, setTsal] = useState(edit_tsal)

  const [symbol, setSymbol] = useState(edit_symbol)
  const [currency, setCurrency] = useState(edit_currency)
  const [m, setM] = useState(edit_m)

  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  //const {setTimeActive} = useAuthValue()
  //const navigate = useNavigate()

  /*useEffect(() => {
    //if(glovar.FORCE_REFRESH_ELEMENT==1) 
    {
    setEntrada(edit_entrada); // This will always use latest value of count
    }
}, [edit_entrada]);*/

  useEffect(() => { setEntrada(edit_entrada); }, [edit_entrada]);
  useEffect(() => { setTitulos(edit_titulos); }, [edit_titulos]);
  useEffect(() => { setTitCerrar(edit_tit_cerrar); /*console.log("set TITULOS A CERRAR:"+edit_tit_cerrar)*/ }, [edit_tit_cerrar]);
  useEffect(() => { setStoploss(edit_stoploss); }, [edit_stoploss]);
  useEffect(() => { setPrecio(edit_precio); }, [edit_precio]);
  useEffect(() => { setGarantia(edit_garantia); }, [edit_garantia]);
  useEffect(() => { setMulti(edit_multi); }, [edit_multi]);
  useEffect(() => { setSentido(edit_sentido); }, [edit_sentido]);
  useEffect(() => { setStock(edit_stock); }, [edit_stock]);


  useEffect(() => { setFent(edit_fent); }, [edit_fent]);
  useEffect(() => { setTemp(edit_temp); }, [edit_temp]);

  useEffect(() => { setFsal(edit_fsal); }, [edit_fsal]);
  useEffect(() => { setTsal(edit_tsal); }, [edit_tsal]);
  useEffect(() => { setSymbol(edit_symbol); }, [edit_symbol]);
  useEffect(() => { setCurrency(edit_currency); }, [edit_currency]);
  useEffect(() => {  setM(edit_m); }, [edit_m]);

  
  //useEffect(() => {timemilis=""+Math.floor(Date.now()/1);});


  //console.log('entrada:'+entrada)
  //console.log("tit_cerrar:"+tit_cerrar);
  //console.log("set TITULOS A CERRAR:"+edit_tit_cerrar);

  const [showMyProfile, setShowMyProfile] = useState(glovar.SHOW_MY_PROFILE);

  const preCloseTrade = e => {
    //const login = async (e) => {
      
    e.preventDefault()
    

    /*
    console.log("hooaaaa")
    console.log('entrada:'+entrada);
    console.log('titulos:'+titulos);
    console.log('tit Cerrar:'+tit_cerrar);
    console.log('stoploss:'+stoploss);
    console.log('precio:'+precio);
    //console.log('garantia:'+garantia);
    //console.log('multi:'+multi);
    //console.log('sentido:'+sentido);
    console.log("adiosss")
    */

    
    /*
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].acciones=titulos; 

    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].ent=entrada;

    //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].obj=objetivo; 
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].stop=stoploss; 
    glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].coti=precio; 
    //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].garantia=garantia;
    //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].multi=multi;
    //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].sentido=sentido;

    glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].stock=stock;

    glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].fent=fent;
    glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].temp=temp;

    glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].fsal=fsal;
    glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].tsal=tsal;
    */

    //glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].symbol=symbol;
    //glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].currency=currency;
    //glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].m=m;

    //eventBus.dispatch("hideClosePosPortfolio", { pos: 0 });
    //De momento no cerramos closeTrade pq hay que ajustar los datos
    closeTrade(offTRADERPOS);

    offTRADERPOS=traderPos;
    offEDITPOS=editPos;

    //var CARTERA_INFO_CARTERA_TEXT='['+glovar.TOPTRADERS_PRO1[offTRADERPOS].cartera_text+']';
    //console.log('##CCARTERA_INFO_CARTERA_TEXT:'+CARTERA_INFO_CARTERA_TEXT);
     
    //var CARTERA_INFO_CARTERA = JSON.parse(CARTERA_INFO_CARTERA_TEXT);

    //glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA[offEDITPOS].acciones=titulos; 
    /*
 edit_objetivo=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].obj; 
 edit_stoploss=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].stop; 
 edit_precio=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].coti; 
 edit_garantia=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].garantia;
 edit_multi=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].multi;
 edit_sentido=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].sentido;
 edit_stock=glovar.TOPTRADERS_PRO1[traderPos].CARTERA_INFO_CARTERA[editPos].stock;

 */

 


/*
 const washingtonRef = doc(db, "toptraders", "Eva_trading");
 
 async () => {
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
 });
 }
 */
 /*
 var washingtonRef = db.collection("toptraders").doc("Eva_trading");

 const cityRef = db.collection('cities').doc('DC');

// Set the 'capital' field of the city
const res = await cityRef.update({capital: true});*/

 /*

// Set the "capital" field of the city 'DC'
return washingtonRef.update({
  cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
})
.then(() => {
    console.log("Document successfully updated!");
})
.catch((error) => {
    // The document probably doesn't exist.
    console.error("Error updating document: ", error);
});
*/

    /*
    try {
      var db = firebase.firestore();
   
      let NOM_USER_FB=glovar.USER_USERNAME;
      let COLLECTION_FB="users";
      
      //if(glovar.MODE_USER==0) {NOM_USER_FB=glovar.ANOM_USER_USERNAME; COLLECTION_FB='users_anom';} 
      
      db.collection("toptraders").doc("Eva_trading").update({
        //historial: glovar.HISTORIAL_LOCAL1,
        cartera:glovar.TOPTRADERS_PRO1[data.pos].cartera_text//glovar.TOPTRADERS_PRO1[offTRADERPOS].CARTERA_INFO_CARTERA
        //numFavoritos: glovar.NUM_FAVORITOS
    })
    .catch(function(error) {
      //console.log('ERROR UPLOAD CARTERA SERVER:'+error);  
    }).then(function() {
      //console.log("###UUPLOAD CARTERA SERVER");
      }
     );
    }
    catch(exxx) {
      //console.log('Petada ERROR UPLOAD carera SERVER:'+exxx);  
    }
    */
   
    
    //glovar.CURRENT_MENU_ID=3;
    //eventBus.dispatch("hideEditPosTrader", { pos: 0 });
    //console.log('commmm pos:'+pos)
    } 
  

 
  return (
    <Box ml={8} mr={8}>
   
    
   
    
    <form className="formFields" onSubmit={preCloseTrade}>

    <input style={{fontSize:"1.2em", fontWeight:"bold", marginBottom:"1pc", marginTop:"-0.3pc", textAlign:"center", width:"96%" }}
     value={stock}
     onChange={e => setStock(e.target.value)}
    
    />


      <HStack marginBottom="1.2pc" justifyContent="space-between">
        <div style={{ width: "8pc", marginRight:"10px", textAlign:"center",  }}>
          <label className="formFieldLabel" htmlFor="email">
            Entrada
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="entrada"
            placeholder={edit_entrada}
            name="entrada"
            value={entrada}
            //required
            onChange={e => setEntrada(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>
       
       

        <div className="formField"    style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Títulos
          </label>
          <input
          style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="titulos"
            placeholder={edit_titulos}
            name="titulos"
            value={titulos}
            //required
            onChange={e => setTitulos(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>

        <div className="formField" style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Títulos a cerrar
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="tit_cerrar"
            placeholder={edit_tit_cerrar}
            name="tit_cerrar"
            value={tit_cerrar}
            //required
            onChange={e => { setTitCerrar(e.target.value); /* console.log("titulos a cerrar:"+e.target.value);*/ } }
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>


        <div style={{ width: "8pc", marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Precio
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="precio"
            placeholder={edit_precio}
            name="precio"
            value={precio}
            //required
            onChange={e => setPrecio(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>

        </HStack>


        

      



        <HStack marginBottom="3pc" justifyContent="space-between">
        <div style={{ width: "8pc", marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            F.Entrada
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="fent"
            placeholder={edit_fent}
            name="fent"
            value={fent}
            //required
            onChange={e => setFent(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>
       
       
              
        <div className="formField"    style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Time Entrada
          </label>
          <input
          style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="temp"
            placeholder={edit_temp}
            name="temp"
            value={temp}
            //required
            onChange={e => setTemp(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>


        <div style={{ width: "8pc", marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            F.Entrada
          </label>
          <input
           style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="text"
            id="fsal"
            placeholder={edit_fsal}
            name="fsal"
            value={fsal}
            //required
            onChange={e => setFsal(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>
       
       
              
        <div className="formField"    style={{ width: "8pc", marginLeft:"10px",marginRight:"10px", textAlign:"center" }}>
          <label className="formFieldLabel" htmlFor="email">
            Time Salida
          </label>
          <input
          style={{ width: "8pc", borderRadius: '4px', border: '2px solid lightgray', textAlign:"center" }}
            type="number"
            id="tsal"
            placeholder={edit_tsal}
            name="tsal"
            value={tsal}
            //required
            onChange={e => setTemp(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange
          />
        </div>




        </HStack>





        <div style={{  maxHeight:"10px", flex:1, textAlign:"center", alignContent:"flex-end"  }}>
        <HStack>
        
          <button className="formFieldOutlineButton"  _hover={{ cursor: "pointer" }} 
          onClick={() => cancelar()}
          style={{  flex:1, marginLeft:"4pc", marginRight:"2pc", width: "14pc", textAlign:"center", fontWeight:700 }}>
          CANCELAR</button>
          
          <button className="formFieldFilledButton"  _hover={{ cursor: "pointer" }} 
         
          style={{ flex:1, marginRight:"6pc", width: "14pc",  textAlign:"center" }}>
          CERRAR POSICIÓN</button>
          
        </HStack>
        </div>
        

     
      
       
      </form>
    
    
    </Box>
  );
}

//export default EditPosTrader